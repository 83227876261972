<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.integrations') }}</strong>
            <back-button></back-button>
        </CCardHeader>
        <CCardBody>
            <b-tabs lazy content-class="mt-3">
                <b-tab v-for="(tab,index) in tabs"
                       :key="tab.key"
                       :active="activeTab==tab.key"
                >
                    <template slot="title">
                        <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                        {{ tab.title }}
                        <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                    </template>
                    <component v-bind:is="tab.tab"
                               :form="tab.configuration"
                               :name="tab.key"
                               :enabled="tab.enabled"
                               :after-save="shown"
                    ></component>
                </b-tab>
            </b-tabs>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'
import {isObject} from "underscore";

const TabFacebook = () => import('./integration/facebook.vue')
const TabMailInvoices = () => import('./integration/mail_invoices.vue')

export default {
    name: 'SettingsIntegrations',
    data() {
        return {
            'activeTab': 'modules',
            'tabs': [],
            'integrations': [],
        }
    },
    methods: {
        ...mapGetters('Integrations', ['getIntegrations']),
        shown() {
            const integrationsFetch = this.$store.dispatch('Integrations/fetchIntegrations')

            const modules = {
                'facebook_marketing': TabFacebook,
                'mail_invoices': TabMailInvoices,
            }
            Promise.all([integrationsFetch])
                .then(() => {
                    this.tabs = []
                    Object.keys(this.getIntegrations()).forEach(key => {
                        if (modules[key]) {
                            this.tabs.push({
                                'key': key,
                                'title': this.$t('settings.integrations.' + key + '.title'),
                                'icon': 'puzzle-piece',
                                'tab': modules[key],
                                'enabled': this.getIntegrations()[key] && this.getIntegrations()[key].enabled,
                                'configuration': this.getIntegrations()[key] ? JSON.parse(JSON.stringify(Array.isArray(this.getIntegrations()[key].configuration) ? {} : this.getIntegrations()[key].configuration)) : {}
                            })
                        }
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>